import React from "react";
import { IoMdOpen } from 'react-icons/io';
import { Link } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
import styles from "./project.module.css";

export default function Project(props) {
    return (
        <div className={props.className}>
            <BackgroundImage
                    Tag="div"
                    className={styles.background}
                    style={{ overflow: 'hidden', width: '100%', height: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'relative', borderRadius: '10px', boxShadow: '0px 3px 8px rgba(0,0,0,.1)' }}
                    fluid={props.image}>
                <Link to={props.slug}>
                    <div style={{ width: '100%', height: '100%', boxShadow: 'inset 0px 0px 70px 70px rgba(0,0,0,0.4)', borderRadius: '10px' }}></div>
                </Link>
                <Link to={props.slug}>
                    <h3 style={{ textShadow: '0px 0px 3px rgba(0,0,0,.9)', color: '#fff', position: 'absolute', left: 0, bottom: 0, margin: '10px' }}>
                        {props.title}
                    </h3>
                </Link>
                <div className={ props.done === true ? 'status done' : 'status wip' } style={{ right: 0, bottom: 0, position: 'absolute', margin: '10px' }}>
                    { props.done === true ? 'DONE' : 'WORK IN PROGRESS' }
                </div>
                { props.link &&
                    <a title="Open external link" style={{ zIndex: 9999, color: '#fff', position: 'absolute', right: 0, top: 0, margin: '10px', fontSize: '20px' }} href={props.link} target="_blank" rel="noopener noreferrer"><IoMdOpen /></a>
                }
            </BackgroundImage>
        </div>
    )
}