import React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import styles from "./projects.module.css";
import Project from "../components/project"
import SEO from "../components/seo";

export default function Projects({data}) {
    const Projects = data.allMarkdownRemark.edges
        .map(e => <Project
            key={e.node.frontmatter.slug}
            slug={e.node.frontmatter.slug} 
            className={styles.item} 
            title={e.node.frontmatter.title} 
            image={e.node.frontmatter.featured.childImageSharp.fluid} 
            done={e.node.frontmatter.done}
            link={e.node.frontmatter.link} />);

    return (
        <Layout>
            <SEO title="Projects" description="Software projects" />
            <h1>Projects</h1>
            <div className={styles.row} style={{ margin: '30px 0' }}>
                { Projects }
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query {
        allMarkdownRemark (
            sort: { fields: [frontmatter___slug], order: ASC }
        ) {
            edges {
                node {
                    frontmatter {
                        done
                        slug
                        title
                        link
                        featured {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;